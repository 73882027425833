"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IConfigurationTransitionEffect = void 0;
var IConfigurationTransitionEffect;
exports.IConfigurationTransitionEffect = IConfigurationTransitionEffect;
(function (IConfigurationTransitionEffect) {
  IConfigurationTransitionEffect[IConfigurationTransitionEffect["NONE"] = 0] = "NONE";
  IConfigurationTransitionEffect[IConfigurationTransitionEffect["OPACITY"] = 1] = "OPACITY";
  IConfigurationTransitionEffect[IConfigurationTransitionEffect["BLUR"] = 2] = "BLUR";
})(IConfigurationTransitionEffect || (exports.IConfigurationTransitionEffect = IConfigurationTransitionEffect = {}));