"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TutorialType = exports.TransparentSort = exports.TextureMimeType = exports.MeshBloomOptions = exports.MediaFormat = exports.IThreeTextureWrap = exports.IPostMessageOrigin = exports.ExportFileType = exports.EventSelectorActionType = exports.DimensionsUnits = exports.AnnotationType = void 0;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var IPostMessageOrigin;
exports.IPostMessageOrigin = IPostMessageOrigin;
(function (IPostMessageOrigin) {
  IPostMessageOrigin[IPostMessageOrigin["TOP"] = 1] = "TOP";
  IPostMessageOrigin[IPostMessageOrigin["MAIN"] = 2] = "MAIN";
  IPostMessageOrigin[IPostMessageOrigin["WORKER"] = 3] = "WORKER";
  IPostMessageOrigin[IPostMessageOrigin["IGNORE"] = 4] = "IGNORE";
})(IPostMessageOrigin || (exports.IPostMessageOrigin = IPostMessageOrigin = {}));
var MeshBloomOptions =
// kernelSize: number;
function MeshBloomOptions() {
  _classCallCheck(this, MeshBloomOptions);
  _defineProperty(this, "luminanceThreshold", void 0);
  _defineProperty(this, "luminanceSmoothing", void 0);
  _defineProperty(this, "resolutionScale", void 0);
  _defineProperty(this, "width", void 0);
  _defineProperty(this, "height", void 0);
  _defineProperty(this, "intensity", void 0);
  this.luminanceThreshold = 0.1;
  this.luminanceSmoothing = 0.025;
  this.resolutionScale = 0.5;
  this.width = 360;
  this.height = 360;
  this.intensity = 1;
};
exports.MeshBloomOptions = MeshBloomOptions;
var DimensionsUnits;
exports.DimensionsUnits = DimensionsUnits;
(function (DimensionsUnits) {
  DimensionsUnits[DimensionsUnits["INCH"] = 1] = "INCH";
  DimensionsUnits[DimensionsUnits["CM"] = 2] = "CM";
})(DimensionsUnits || (exports.DimensionsUnits = DimensionsUnits = {}));
var TransparentSort;
exports.TransparentSort = TransparentSort;
(function (TransparentSort) {
  TransparentSort[TransparentSort["Z_INDEX"] = 1] = "Z_INDEX";
  TransparentSort[TransparentSort["REVERSE_PAINTRE_SORT_STABLE"] = 2] = "REVERSE_PAINTRE_SORT_STABLE";
  TransparentSort[TransparentSort["PAINTER_SORT_STABLE"] = 3] = "PAINTER_SORT_STABLE";
})(TransparentSort || (exports.TransparentSort = TransparentSort = {}));
var IThreeTextureWrap;
exports.IThreeTextureWrap = IThreeTextureWrap;
(function (IThreeTextureWrap) {
  IThreeTextureWrap[IThreeTextureWrap["RepeatWrapping"] = 1000] = "RepeatWrapping";
  IThreeTextureWrap[IThreeTextureWrap["ClampToEdgeWrapping"] = 1001] = "ClampToEdgeWrapping";
  IThreeTextureWrap[IThreeTextureWrap["MirroredRepeatWrapping"] = 1002] = "MirroredRepeatWrapping";
})(IThreeTextureWrap || (exports.IThreeTextureWrap = IThreeTextureWrap = {}));
var AnnotationType;
exports.AnnotationType = AnnotationType;
(function (AnnotationType) {
  AnnotationType[AnnotationType["HOT_SPOT"] = 1] = "HOT_SPOT";
})(AnnotationType || (exports.AnnotationType = AnnotationType = {}));
var EventSelectorActionType;
exports.EventSelectorActionType = EventSelectorActionType;
(function (EventSelectorActionType) {
  EventSelectorActionType[EventSelectorActionType["SCOPE"] = 1] = "SCOPE";
  EventSelectorActionType[EventSelectorActionType["EVAL"] = 2] = "EVAL";
})(EventSelectorActionType || (exports.EventSelectorActionType = EventSelectorActionType = {}));
var TutorialType;
exports.TutorialType = TutorialType;
(function (TutorialType) {
  TutorialType[TutorialType["LEGACY_ZOOM"] = 1] = "LEGACY_ZOOM";
  TutorialType[TutorialType["LEGACY_ROTATE"] = 2] = "LEGACY_ROTATE";
  TutorialType[TutorialType["INTERACTIVE_ROTATE"] = 3] = "INTERACTIVE_ROTATE";
})(TutorialType || (exports.TutorialType = TutorialType = {}));
var ExportFileType;
exports.ExportFileType = ExportFileType;
(function (ExportFileType) {
  ExportFileType[ExportFileType["glTF"] = 1] = "glTF";
  ExportFileType[ExportFileType["GLB"] = 2] = "GLB";
  ExportFileType[ExportFileType["OBJ"] = 3] = "OBJ";
  ExportFileType[ExportFileType["USDZ"] = 4] = "USDZ";
})(ExportFileType || (exports.ExportFileType = ExportFileType = {}));
var MediaFormat;
exports.MediaFormat = MediaFormat;
(function (MediaFormat) {
  MediaFormat[MediaFormat["IMAGE"] = 1] = "IMAGE";
  MediaFormat[MediaFormat["VIDEO"] = 2] = "VIDEO";
})(MediaFormat || (exports.MediaFormat = MediaFormat = {}));
var TextureMimeType;
exports.TextureMimeType = TextureMimeType;
(function (TextureMimeType) {
  TextureMimeType[TextureMimeType["IMAGE"] = 1] = "IMAGE";
  TextureMimeType[TextureMimeType["VIDEO"] = 2] = "VIDEO";
})(TextureMimeType || (exports.TextureMimeType = TextureMimeType = {}));