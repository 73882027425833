<div class="img pos-rel poiner">
  <button
    type="button"
    class="link-image pos-abs"
    mat-icon-button
    (click)="view(src)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
  <img [src]="src | srcSuffix : '?w=200&h=200' : true" alt="option ${$index}" />
</div>
