import {
  IConfigurationTransitionEffect,
  ViewerCommunicator,
} from 'hexa-viewer-communicator';
import { Subject } from 'rxjs';
import { UtilsService } from './utils.service';
import { environment } from '../../environments/environment';
import { ScriptsFetcherType, ShareObject } from './utils';
import { ScreenNotificationType } from './enums';
import { themeColor, themeSeconderyColor } from './constants';

export class ViewerService {
  private _viewerUrl: string;
  private _glbUrl: string;
  private _canRefine: boolean;
  private _canDownlopad: boolean;
  private _generatePreview: boolean;
  private _wireframe: boolean;
  private _uv: boolean;
  private _matcap: boolean;
  private _standard: boolean;
  public onDownload: Subject<unknown>;
  public onRefine: Subject<unknown>;
  public onPreview: Subject<string>;
  public style: any;
  private _vc: ViewerCommunicator;
  private utils: UtilsService;
  private _element: HTMLElement;
  constructor(utils: UtilsService) {
    this.utils = utils;
    this.style = {};
    this.onDownload = new Subject<unknown>();
    this.onRefine = new Subject<unknown>();
    this.onPreview = new Subject<string>();
  }

  public get glbUrl(): string {
    return this._glbUrl;
  }

  public set glbUrl(value: string) {
    this._glbUrl = value;
  }

  public get viewerUrl(): string {
    return this._viewerUrl;
  }

  async setViewerUrl(viewerUrl: string) {
    if (this._viewerUrl !== viewerUrl) {
      if (viewerUrl) {
        if (
          !this.utils.getUrlParam(viewerUrl, 'server') &&
          this.utils.getDomain(viewerUrl) === 'vqa.hexa3d.io'
        ) {
          viewerUrl = this.utils.setUrlParam(viewerUrl, 'server', '2');
        }
        viewerUrl = this.utils.setUrlParam(viewerUrl, 'watermark', null);
        viewerUrl = this.utils.setUrlParam(viewerUrl, 'pp-sharpen', '1');
        viewerUrl = this.utils.setUrlParam(viewerUrl, 'closeup', 'true');
        viewerUrl = this.utils.setUrlParam(
          viewerUrl,
          'theme-color',
          themeColor
        );
        if (this.vc) {
          this.vc.configurator.setConfigurator([
            {
              color: 'rgb(255, 255, 255)',
              asset: viewerUrl,
            },
          ]);
          this.vc.configurator.toggleConfiguratorUI(false);
          this.vc.configurator.setConfiguratorEffect(
            IConfigurationTransitionEffect.BLUR
          );
          this.vc.configurator.selectConfigurator(0);
          this._viewerUrl = viewerUrl;
        } else {
          this._viewerUrl = viewerUrl;
          await this.setSrc();
        }
      }
      this.uv = false;
      this.matcap = false;
      this.wireframe = false;
      this.standard = true;
    }
  }

  public get canRefine(): boolean {
    return this._canRefine;
  }

  public set canRefine(canRefine: boolean) {
    this._canRefine = canRefine;
  }

  public get canDownlopad(): boolean {
    return this._canDownlopad;
  }

  public set canDownlopad(canDownlopad: boolean) {
    this._canDownlopad = canDownlopad;
  }

  public get generatePreview(): boolean {
    return this._generatePreview;
  }

  public set generatePreview(generatePreview: boolean) {
    this._generatePreview = generatePreview;
  }

  public get wireframe(): boolean {
    return this._wireframe;
  }

  public set wireframe(wireframe: boolean) {
    if (this.uv)
      this.uv = false;
    if (this.matcap)
      this.matcap = false;
    if (this.standard)
      this.standard = false;
    this._wireframe = wireframe;
    this.vc.toggleWireframe(this._wireframe);
  }

  public get uv(): boolean {
    return this._uv;
  }

  public set uv(uv: boolean) {
    if (this.wireframe)
      this.wireframe = false;
    if (this.matcap)
      this.matcap = false;
    if (this.standard)
      this.standard = false;
    this._uv = uv;
    this.vc.toggleUvMode(this._uv);
  }

  public get matcap(): boolean {
    return this._matcap;
  }

  public set matcap(matcap: boolean) {
    if (this.wireframe)
      this.wireframe = false;
    if (this.uv)
      this.uv = false;
    if (this.standard)
      this.standard = false;
    this._matcap = matcap;
    this.vc.toggleMatcapMode(this._matcap, { color: themeSeconderyColor });
  }

  public get standard(): boolean {
    return this._standard;
  }

  public set standard(standard: boolean) {
    if (this.wireframe)
      this.wireframe = false;
    if (this.uv)
      this.uv = false;
    if (this.matcap)
      this.matcap = false;
    this._standard = standard;
  }

  public get vc(): ViewerCommunicator {
    return this._vc;
  }

  public set vc(vc: ViewerCommunicator) {
    this._vc = vc;
  }

  get element() {
    return this._element;
  }

  async injectScript() {
    this.utils.fetchCode(environment.viewerScript, ScriptsFetcherType.SCRIPT);
  }

  defineVC(element?: HTMLElement) {
    this.vc = new ViewerCommunicator({ hexaViewer: element });
    this._element = element;
  }

  async setSrc() {
    if (this.viewerUrl) {
      await this.injectScript();
      if (this.vc?.hasDestroyed) this.vc = null;
      if (!this.vc) {
        this.vc = new ViewerCommunicator();
      }
      if (!this.vc.hexaViewer) {
        this._element = await this.vc.createInstanceFromUrl(this.viewerUrl);
        // this._element.classList.add('sharpen1');
        if (this.generatePreview) this.emitPreview();
      }
      // setTimeout(async () => {
      //   const blob = await this.vc.getScreenshotsSequence({
      //     format: MediaFormat.VIDEO,
      //     numOfFrames: 96,
      //     codec: 'vp9'
      //   }) as Blob;
      //   const url = URL.createObjectURL(blob);
      //   console.log(url);
      //   // this.utils.multipleDownloads([url], 'v.mkv');
      //   // setTimeout(() => {
      //   //   URL.revokeObjectURL(url);
      //   // });
      // }, 5000);
    }
  }

  async emitPreview() {
    this.onPreview.next(await this.getPreview());
  }

  async getPreview() {
    return await this.vc.getCurrentScreenshot();
  }

  download() {
    this.onDownload.next(null);
  }

  refine() {
    this.onRefine.next(null);
  }

  share() {
    const so = new ShareObject();
    so.url = `${location.protocol}//${location.host}/preview?view=${encodeURIComponent(this.viewerUrl)}`;
    try {
      this.utils.share(so);
    } catch (e) {
      console.warn(e);
      this.utils.copyClipboard(this.viewerUrl);
      this.utils.notifyUser({
        type: ScreenNotificationType.Info,
        text: 'Link copied to clipboard',
      });
    }
  }

  destroy() {
    this._element = null;
    this.style = {};
    if (this.vc) this.vc.destroy();
  }
}
